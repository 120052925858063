import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Nous avons fait appel à Aman Technology pour travailler sur plusieurs MVP. Leur capacité à comprendre précisément nos besoins s'est avérée exceptionnelle, car ils nous ont fourni une équipe de seniors répondant parfaitement à nos attentes.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Abdelhakim Akrime </span> - Director of Operations, Wemanity Maroc</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Aman Technology m’a permis de répondre favorablement à une très belle opportunité chez Disneyland Paris qui convenait exactement à ce que je recherchais. On m’a également accompagné tout au long du processus de recrutement. Je suis donc très satisfait de vos services et vous remercie vivement !</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Ayoub El Ouarak </span> - React Native Developer, Dinseyland Paris.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Je suis très heureux d’avoir intégré Aman Technology et les remercie pour leur confiance et la qualité de leur accompagnement. C’est très agréable de pouvoir échanger avec des professionnels qui connaissent mon métier. J’ai maintenant hâte de commencer de très belles missions avec vous !</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Mouhcine Fairadi</span> - Javascript Developer, Acensi France.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-10.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-11.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/testimonial-12.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
        
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;