import React, { Component } from "react";
import FormStyle from './FormStyle'

class ContactTwo extends Component{
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <span className="subtitle">Contactez-nous</span>
                                <h2 className="title">Parlez-nous de vous</h2>
                                <div className="im_address">
                                    <span>Tél:</span>
                                    <a className="link im-hover" target="_blank" href="https://api.whatsapp.com/send?text=212668135905">+212 6 68 13 59 05</a>
                                </div>
                                <div className="im_address mt--5">
                                    <span>Email:</span>
                                    <a className="link im-hover" href={`mailto:${'contact@aman-devit.com'}?subject=${encodeURIComponent('Proposition de collaboration') || ''}&body=${encodeURIComponent('Bonjour') || ''}`}>contact@aman-technology.com</a>
                                </div>
                            </div>
                            <FormStyle />
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <div style={{ paddingTop: "120px" }}>
                                 <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6646.962843148188!2d-7.6321705730185005!3d33.59281128154574!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d292c349c9a7%3A0xc8d3a14dd4eae969!2sOffice%20Space%20Jean%20Jaur%C3%A8s!5e0!3m2!1sfr!2suk!4v1707815986877!5m2!1sfr!2suk"
                                        width="516"
                                        height="516"
                                        style={{ border: "0" }}
                                        allowFullScreen=""
                                        loading="lazy"
                                ></iframe>
                                </div>
           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;